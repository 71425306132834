import React from "react";
import { Row, Col } from "react-bootstrap";
import Logo from "../../../images/partners/istqb.png";

const wso = () => {
  return (
    <div className="partner-detail-card">
      <Row>
        <div className="top-section">
          <div>
            <img className="img-fluid" src={Logo} alt="PartnerImage" />
          </div>
          <div className="padding-add">
            <h3>International Software Testing Qualifications Board</h3>
            <div className="lbl-ptnrn">ISTQB Platinum Partner</div>
            <div>
              <a href="https://www.istqb.org/" target="_blank">
                Visit Website
              </a>{" "}
              <span></span>
            </div>
          </div>
        </div>
      </Row>
      <Row>
        <Col>
          <div className="mt-3">
            <h4>About ISTQB</h4>
            <p>
              The International Software Testing Qualifications Board is a
              software testing certification board that operates
              internationally. Founded in Edinburgh in November 2002, the ISTQB
              is a non-profit association legally registered in Belgium.
            </p>
          </div>
          <div className="mt-3">
            <h4>ISTQB Partner Program</h4>
            <p>
              The ISTQB&reg; Partner Program recognizes organizations with a
              demonstrated commitment to software testing certifications.
            </p>
          </div>
          <div className="mt-3">
            <h4> Focus Areas</h4>
            <div className="p"><span className="vdivide">-{" "}</span>Software Quality Assurance</div>
            <div className="p"><span className="vdivide">-{" "}</span>Test Automation</div>
            <div className="p"><span className="vdivide">-{" "}</span>Specialized Testing Service</div>
            <div className="p"><span className="vdivide">-{" "}</span>Training </div>
          </div>
          <div className="mt-3">
            <h4>Operating Countries</h4>
            <p>Australia <span className="vdivide">|</span> Singapore <span className="vdivide">|</span> UK <span className="vdivide">|</span> Africa <span className="vdivide">|</span> EMEA <span className="vdivide">|</span> Sri Lanka <span className="vdivide">|</span> India <span className="vdivide">|</span> Pakistan </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default wso;
